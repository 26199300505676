<template>
    <div>
        <section v-show="!importation" class="box">
            <div class="mb-3 d-flex justify-content-end ">

                <b-dropdown aria-role="list" position="is-bottom-left">
                    <template #trigger="{ active }">
                        <b-button
                            label="Exports"
                            type="is-warning"
                            :icon-right="active ? 'caret-up' : 'caret-down'"/>
                    </template>
                    <b-dropdown-item aria-role="listitem" class="mr-3 " @click="doExport">
                        <div class="media is-flex py-2">
                            <b-icon class="media-left align-self-center" icon="file-download" size="is-large"></b-icon>
                            <div class="media-content">
                                <h3>Export des transactions</h3>
                                <small>Export comptable des factures</small>
                            </div>
                        </div>
                    </b-dropdown-item>

                    <b-dropdown-item aria-role="listitem" class="mr-3" @click="doCreditsExport">
                        <div class="media is-flex py-2">
                            <b-icon class="media-left align-self-center" icon="euro-sign" size="is-large"></b-icon>
                            <div class="media-content">
                                <h3 class="p-0">Export des crédits</h3>
                                <small>Export comptable des factures</small>
                            </div>
                        </div>
                    </b-dropdown-item>
                    <b-dropdown-item aria-role="listitem" class="mr-3" @click="doGainExport">
                        <div class="media is-flex py-2">
                            <b-icon class="media-left align-self-center" icon="coins" size="is-large"></b-icon>
                            <div class="media-content">
                                <h3 class="p-0">Export des gains</h3>
                                <small>Export comptable des gains des partenaires</small>
                            </div>
                        </div>
                    </b-dropdown-item>
                </b-dropdown>

            </div>
            <b-tabs v-model="activeTab" @input="updateFilters">
                <b-tab-item v-for="tab in tabs" :key="tab.index" :label="tab.label" :icon="tab.icon" :value="tab.type">
                    <billing-list :active="activeTab === tab.type"/>
                </b-tab-item>
            </b-tabs>
        </section>
        <div v-show="importation">
            <div class="row">
                <div class="col-md-8">
                    <div class="mb-3 d-flex align-items-center">
                        <button class="partenaires-sites-ajout btn btn-secondary mr-2" @click="closeImport">
                            Retour
                        </button>
                    </div>
                </div>
            </div>
            <section class="box">
                <Import/>
            </section>
        </div>

        <OrdersExportModal ref="exportModal"></OrdersExportModal>
        <CreditsExportModal ref="exportCreditsModal"></CreditsExportModal>
        <GainsExportModal ref="exportGainsModal"></GainsExportModal>
    </div>
</template>

<script>
import BillingList from '../../components/Admin/Billing/BillingList.vue'
import Import from '../../components/Admin/Billing/Import.vue'
import OrdersExportModal from '@/components/Admin/Orders/OrdersExportModal.vue'
import CreditsExportModal from '@/components/Admin/Orders/CreditsExportModal.vue'
import GainsExportModal from '@/components/Admin/Orders/GainsExportModal.vue'
import {mapState} from "vuex";

export default {
    title: 'layout.billing',
    components: {
        BillingList,
        Import,
        OrdersExportModal,
        CreditsExportModal,
        GainsExportModal,
    },
    data: () => ({
        importation: false,
        activeTab: null,
        filters: {
            pending: {
                payment_status: 'pending'
            },
            in_progress: {
                payment_status: 'in_progress_invoice,in_progress_credits'
            },
            complete: {
                payment_status: 'complete'
            },
            cancel: {
                payment_status: 'cancel'
            }
        },
        tabs: [
            {
                type: 'all',
                label: 'All',
                icon: 'asterisk'
            },
            {
                type: 'pending',
                label: 'En attente',
                icon: 'hourglass-half',
            },
            {
                type: 'in_progress',
                label: 'En cours',
                icon: 'hourglass-half',
            },
            {
                type: 'complete',
                label: 'Terminé',
                icon: 'check',
            },
            {
                type: 'cancel',
                label: 'Cancel',
                icon: 'ban',
            }
        ]
    }),
    computed: {
        ...mapState('billing', {
            config: state => state.ordersConfig,
        })
    },
    mounted() {
        this.activeTab = 'all'
    },
    methods: {
        openImport() {
            this.importation = true
            this.activeTab = 'cancel'
        },
        closeImport() {
            this.importation = false
            this.activeTab = 'all'
        },
        doExport() {
            this.$refs.exportModal.open()
        },
        doCreditsExport() {
            this.$refs.exportCreditsModal.open()
        },
        doGainExport() {
            this.$refs.exportGainsModal.open()
        },

        updateFilters(tab) {
            this.config.filters = {...this.filters[tab]}
            console.log(this.config.filters)
        }
    }
}
</script>

<style scoped>
:deep(.dropdown-content) {
    padding: 0;
}

:deep(.dropdown-menu) {
    padding: 0;
}

:deep(.dropdown-item) {
    border-bottom: 1px solid #dee2e6;
}

:deep(.dropdown-item:last-child) {
    border-bottom: none;
}


</style>